import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import eheImage from '../../images/deeper-dive-ehe-eta.png';

const RelatedContent = props => {
    return (
        <Container className='related-content section mt-2 pt-1 mb-4'>
            {props.page === 'blind-angels' && (
                <Row>
                    <Col xs={12} className='m-auto '>
                        <h2 className='title'>RELATED CONTENT</h2>
                    </Col>
                </Row>
            )}
            <Row>
                <Col xs={12} className='m-auto'>
                    <Container>
                        <h4
                            style={{
                                borderBottom: '2px solid #a9253f',
                                marginLeft: '-15px',
                                marginRight: '-15px',
                                letterSpacing: '2px',
                                fontSize: '18px',
                                paddingTop: '20px',
                                paddingBottom: '15px',
                                marginBottom: '20px'
                            }}
                        >
                            TESTING AND PREVENTION
                        </h4>
                        <Row>
                            <a
                                className='light-grey related-content-card height-480px mb-3 mb-lg-4 mr-0 mr-lg-3'
                                href='../../deeper-dive/callen-lorde'
                            >
                                <h3 className='related-content-card-header'>
                                    Gilead Sponsors Callen-Lorde's National HIV Testing Day Event
                                </h3>
                                <p className='related-content-card-subtext'>
                                    Check out our conversation with Aruna Krishnakumar, Managing
                                    Director Adolescent & Mobile Health at Callen-Lorde, who
                                    discusses how Callen-Lorde will help bring HIV testing to the
                                    community in a live event in New York City.
                                </p>
                                <span className='read-more'>
                                    <span className='font-heavy'>
                                        <span class='more-link'>Read more</span>
                                    </span>
                                    <span class='empty-space'>&nbsp;&nbsp;&nbsp;</span>
                                    <i className='fa fa-chevron-right '></i>
                                </span>
                            </a>
                            <a
                                className='light-grey related-content-card height-480px mb-3 mb-lg-4 mr-0 mr-lg-3'
                                href='../../community-involvement/blind-angels/zakia'
                            >
                                <h3 className='related-content-card-header'>
                                    Mobile Health Units Bring <br class='desktop' />
                                    HIV Testing to Underserved Communities in the{' '}
                                    <br class='desktop' />
                                    American South
                                </h3>
                                <p className='related-content-card-subtext'>
                                    Check out our conversation with Nationz Foundation Executive
                                    Director Zakia McKensey.
                                </p>
                                <span className='read-more'>
                                    <span className='font-heavy'>
                                        <span class='more-link'>Read more</span>
                                    </span>
                                    <span class='empty-space'>&nbsp;&nbsp;&nbsp;</span>
                                    <i className='fa fa-chevron-right '></i>
                                </span>
                            </a>
                            <a
                                className='light-grey related-content-card height-480px mb-3 mb-lg-4'
                                href='../../community-involvement/blind-angels/jose-joaquin'
                            >
                                <h3 className='related-content-card-header'>
                                    Overcoming Barriers <br class='desktop' />
                                    to PrEP Medicine Use in <br class='desktop' />
                                    Hispanic/Latino Communities <br className='desktop' />
                                    in the American South
                                </h3>
                                <p className='related-content-card-subtext'>
                                    Discover how HIV advocates José Romero and Joaquín Carcaño
                                    approach the topic of HIV prevention in the South.
                                </p>
                                <p className='related-content-card-note'>
                                    PrEP=pre-exposure prophylaxis.
                                </p>
                                <span className='read-more'>
                                    <span className='font-heavy'>
                                        <span class='more-link'>Read more</span>
                                    </span>
                                    <span class='empty-space'>&nbsp;&nbsp;&nbsp;</span>
                                    <i className='fa fa-chevron-right '></i>
                                </span>
                            </a>
                            <a
                                className='light-grey related-content-card height-480px mb-3 mb-lg-4 mr-0 mr-lg-3'
                                href='../../community-involvement/blind-angels/dazon'
                            >
                                <h3 className='related-content-card-header'>
                                    Empowering Black Women <br class='desktop' />
                                    in the American South to <br class='desktop' />
                                    Protect Their Sexual Health
                                </h3>
                                <p className='related-content-card-subtext'>
                                    Learn about the work Dázon Dixon Diallo is doing to improve
                                    access to care for women who live with and are at risk for HIV.
                                </p>
                                <span className='read-more'>
                                    <span className='font-heavy'>
                                        <span class='more-link'>Read more</span>
                                    </span>
                                    <span class='empty-space'>&nbsp;&nbsp;&nbsp;</span>
                                    <i className='fa fa-chevron-right '></i>
                                </span>
                            </a>
                            <a
                                className='light-grey related-content-card height-480px mb-3 mb-lg-4 mr-0 mr-lg-3'
                                href='../../deeper-dive/marlene-mcneese'
                            >
                                <h3 className='related-content-card-header'>
                                    Recovering From the Impact of the COVID-19 Pandemic on HIV
                                    Testing
                                </h3>
                                <p className='related-content-card-subtext'>
                                    Read our interview with Marlene McNeese, co-chair of the
                                    Presidential Advisory Council on HIV/AIDS (PACHA), who discusses
                                    the impact of the pandemic on HIV testing and innovative
                                    strategies to reinvigorate testing urgency.
                                </p>
                                <span className='read-more'>
                                    <span className='font-heavy'>
                                        <span class='more-link'>Read more</span>
                                    </span>
                                    &nbsp;&nbsp;&nbsp;<i className='fa fa-chevron-right '></i>
                                </span>
                            </a>
                        </Row>
                        <h4
                            style={{
                                borderBottom: '2px solid #a9253f',
                                marginLeft: '-15px',
                                marginRight: '-15px',
                                letterSpacing: '2px',
                                fontSize: '18px',
                                paddingTop: '20px',
                                paddingBottom: '15px',
                                marginBottom: '20px'
                            }}
                        >
                            BARRIERS TO CARE
                        </h4>
                        <Row className='justify-content-lg-between'>
                            {/* Tiffany West */}

                            <a
                                className='light-grey related-content-card height-400px mb-3 mb-lg-4'
                                href='../../deeper-dive/tiffany-west'
                            >
                                <h3 className='related-content-card-header'>
                                    Advancing Health Equity With Historically Black Colleges and
                                    Universities
                                </h3>
                                <p className='related-content-card-subtext'>
                                    Tiffany West, Senior Director of Advancing Health and Black
                                    Equity, shares how Gilead is helping advance health equity with
                                    the Black community and meeting people where they are.
                                </p>
                                <span className='read-more'>
                                    <span className='font-heavy'>
                                        <span class='more-link'>Learn more</span>
                                    </span>
                                    <span class='empty-space'>&nbsp;&nbsp;&nbsp;</span>
                                    <i className='fa fa-chevron-right '></i>
                                </span>
                            </a>

                            {/* Housing Stability */}

                            <a
                                className='light-grey related-content-card height-400px mb-3 mb-lg-4'
                                href='../../community-involvement/blind-angels/kayla'
                            >
                                <h3 className='related-content-card-header'>
                                    The Importance of Housing Stability for Transgender Women of
                                    Color in HIV Treatment
                                </h3>
                                <p className='related-content-card-subtext'>
                                    Discover how Kayla Rena Gore's grassroots organization is
                                    advocating for transgender women of color and their need for
                                    stable housing.
                                </p>

                                <span className='read-more'>
                                    <span className='font-heavy'>
                                        <span class='more-link'>Read more</span>
                                    </span>
                                    <span class='empty-space'>&nbsp;&nbsp;&nbsp;</span>
                                    <i className='fa fa-chevron-right '></i>
                                </span>
                            </a>

                            {/* Overcoming Barriers */}

                            <a
                                className='light-grey related-content-card height-400px mb-3 mb-lg-4'
                                href='../../community-involvement/blind-angels/tony'
                            >
                                <h3 className='related-content-card-header'>
                                    Overcoming Barriers to HIV Care for Black MSM in the American
                                    South
                                </h3>
                                <p className='related-content-card-subtext'>
                                    Learn how HIV advocate Tony Christon-Walker helps address
                                    barriers to care for Black MSM <br class='desktop' />
                                    in Alabama.
                                </p>
                                <p className='related-content-card-note'>
                                    MSM=men who have sex with men.
                                </p>
                                <span className='read-more'>
                                    <span className='font-heavy'>
                                        <span class='more-link'>Read more</span>
                                    </span>
                                    <span class='empty-space'>&nbsp;&nbsp;&nbsp;</span>
                                    <i className='fa fa-chevron-right '></i>
                                </span>
                            </a>
                        </Row>

                        <Row>

                            {/* Overcoming Barriers */}

                            <a
                                className='light-grey related-content-card height-400px mb-3 mb-lg-4'
                                href='/deeper-dive/kayla-quimbley-video'
                            >
                                <h3 className='related-content-card-header'>
                                    From Poetry to Presidents
                                </h3>
                                <p className='related-content-card-subtext kayla-quimbley'>
                                    Kayla Quimbley—the youngest member of the Presidential Advisory Council on HIV/AIDS—is shifting perspectives on what it means to live with HIV. Check out a video of her live performance of her poem, "Thriving with HIV," from Gilead's plenary session at USCHA 2023.
                                </p>
                                <span className='read-more'>
                                    <span className='font-heavy'>
                                        <span class='more-link'>Read more</span>
                                    </span>
                                    <span class='empty-space'>&nbsp;&nbsp;&nbsp;</span>
                                    <i className='fa fa-chevron-right '></i>
                                </span>
                            </a>

                        </Row>

                        <h4
                            style={{
                                borderBottom: '2px solid #a9253f',
                                marginLeft: '-15px',
                                marginRight: '-15px',
                                letterSpacing: '2px',
                                fontSize: '18px',
                                paddingTop: '20px',
                                paddingBottom: '15px',
                                marginBottom: '20px'
                            }}
                        >
                            CONFERENCES
                        </h4>
                        <Row className=''>
                            <a
                                className='light-grey related-content-card height-400px mb-3 mb-lg-4'
                                href='/deeper-dive/USCHA-conference-2022'
                            >
                                <h3 className='related-content-card-header'>
                                    2022 USCHA Conference
                                </h3>
                                <p className='related-content-card-subtext top-100px'>
                                    Learn more about our programming focused on highlighting the
                                    needs of the Puerto Rican and Latin American communities as they
                                    relate to helping end the HIV epidemic.
                                </p>

                                <span className='read-more'>
                                    <span className='font-heavy'>
                                        <span class='more-link'>Learn more</span>
                                    </span>
                                    <span class='empty-space'>&nbsp;&nbsp;&nbsp;</span>
                                    <i className='fa fa-chevron-right '></i>
                                </span>
                            </a>
                            <a
                                className='light-grey related-content-card height-400px mb-3 mb-lg-4 ml-lg-4'
                                href='/deeper-dive/24th-ias-conference-2022'
                            >
                                <h3 className='related-content-card-header'>
                                    Join Us at AIDS 2022
                                </h3>
                                <p className='related-content-card-subtext top-100px'>
                                    Learn more about the steps Gilead HIV is taking to help end the
                                    global HIV epidemic and how innovation, resilience, and
                                    community can be tools to drive progress.
                                </p>

                                <span className='read-more'>
                                    <span className='font-heavy'>
                                        <span class='more-link'>Learn more</span>
                                    </span>
                                    <span class='empty-space'>&nbsp;&nbsp;&nbsp;</span>
                                    <i className='fa fa-chevron-right '></i>
                                </span>
                            </a>
                        </Row>
                        <h4
                            style={{
                                borderBottom: '2px solid #a9253f',
                                marginLeft: '-15px',
                                marginRight: '-15px',
                                letterSpacing: '2px',
                                fontSize: '18px',
                                paddingTop: '20px',
                                paddingBottom: '15px',
                                marginBottom: '20px'
                            }}
                        >
                            TRAININGS
                        </h4>
                        <Row className='ehe-masterclass-trainings'>
                            <Col md={6} lg={5} className='ehe-masterclass-trainings__info'>
                                <div className='info'>
                                    <a
                                        href='/deeper-dive/ehe-masterclass'
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <h3 className='info-title'>
                                            Ending the HIV Epidemic (EHE) Masterclass
                                        </h3>
                                        <p>
                                            Check out Dr Oni Blackstock and Greg Millet––leaders in
                                            HIV prevention and policy development, respectively––as
                                            they explore the importance of accelerating progress to
                                            help end the HIV epidemic in this exclusive masterclass,
                                            moderated by Dr Patrick Sullivan.
                                        </p>
                                        <span className='read-more'>
                                            <span className='font-heavy'>
                                                <span class='more-link' style={{ color: 'black' }}>
                                                    Access course
                                                </span>
                                            </span>
                                            <span class='empty-space'>&nbsp;&nbsp;&nbsp;</span>
                                            <i className='fa fa-chevron-right '></i>
                                        </span>
                                    </a>
                                </div>
                            </Col>
                            <Col
                                md={6}
                                lg={3}
                                className='ehe-masterclass-trainings__image pl-0 pr-0'
                            >
                                <img
                                    className='ehe-image'
                                    src={eheImage}
                                    alt='Group of people looking into the camera'
                                />
                            </Col>
                            <div className='container-ehe-masterclass'>
                                {/* <a
                  className="light-grey related-content-card mb-3 mb-lg-2"
                  href="../../community-involvement/blind-angels/kayla"
                >
                  <h3 className="related-content-card-header">
                    Ending the HIV Epidemic (EHE) Masterclass
                  </h3>
                  <p className="related-content-card-subtext">
                    Check out Dr Oni Blackstock and Greg Millet-leaders in HIV
                    prevention and policy development, respectively-explore the
                    importance of accelerating progress to help end the HIV
                    epidemic in this exclusive training, moderated by Dr Patrick
                    Sullivan.
                  </p>

                  <span className="read-more">
                    <span className="font-heavy">
                      <span class="more-link">Acces course</span>
                    </span>
                    <span class="empty-space">&nbsp;&nbsp;&nbsp;</span>
                    <i className="fa fa-chevron-right "></i>
                  </span>
                </a> */}
                            </div>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
};

export default RelatedContent;

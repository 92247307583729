import * as React from "react";
import { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import RelatedContent from "./related";

export default class Content extends Component {
  render() {
    return (
      <div className={`${this.props.show ? "d-block" : "d-none"}`}>
        <Container
          fluid
          className="hero deeper-dive"
          role="img"
          aria-label="Two people hugging and
                      smiling"
        />
        <Container className="deeper-dive">
          <Row>
            <Col xs={10} lg={10} className="m-auto px-0">
              <div className="hero-callout muted-yellow-dark col-lg-9 col-12">
                <h1 className="title">
                  <span
                    style={{
                      display: "inline-block",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    DEEPER DIVE
                  </span>{" "}
                  <br></br>
                  FEATURED ARTICLES
                </h1>
              </div>
              <p className="header-text">
                Explore relevant articles, resources, and more, with a focus on
                what's happening within the HIV community today, and the people
                laying the groundwork to help end the HIV epidemic.
              </p>
              <RelatedContent page="content-index" />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
